@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 200;
  src: url(./files/MonaspaceXenon-ExtraLight.woff) format("woff");
}

@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 300;
  src: url(./files/MonaspaceXenon-Light.woff) format("woff");
}

@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 400;
  src: url(./files/MonaspaceXenon-Regular.woff) format("woff");
}

@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 500;
  src: url(./files/MonaspaceXenon-Medium.woff) format("woff");
}

@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 600;
  src: url(./files/MonaspaceXenon-SemiBold.woff) format("woff");
}

@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 700;
  src: url(./files/MonaspaceXenon-Bold.woff) format("woff");
}

@font-face {
  font-family: "Monaspace Xenon";
  font-style: "normal";
  font-display: swap;
  font-weight: 800;
  src: url(./files/MonaspaceXenon-ExtraBold.woff) format("woff");
}
